import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex items-center justify-center w-full min-h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_SingleLayout = _resolveComponent("SingleLayout")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (_ctx.patient)
    ? (_openBlock(), _createBlock(_component_SingleLayout, {
        key: 0,
        patient: _ctx.patient
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_RouterView, {
            key: _ctx.loadedPatientId,
            patient: _ctx.patient,
            onUpdated: _ctx.update,
            onUpdatePatient: _ctx.fetchPatient
          }, null, 8, ["patient", "onUpdated", "onUpdatePatient"]))
        ]),
        _: 1
      }, 8, ["patient"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageLoading)
      ]))
}